exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artist-strapi-artist-slug-tsx": () => import("./../../../src/pages/artist/{StrapiArtist.Slug}.tsx" /* webpackChunkName: "component---src-pages-artist-strapi-artist-slug-tsx" */),
  "component---src-pages-artists-tsx": () => import("./../../../src/pages/artists.tsx" /* webpackChunkName: "component---src-pages-artists-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lineup-tsx": () => import("./../../../src/pages/lineup.tsx" /* webpackChunkName: "component---src-pages-lineup-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-programs-tsx": () => import("./../../../src/pages/programs.tsx" /* webpackChunkName: "component---src-pages-programs-tsx" */),
  "component---src-pages-summary-tsx": () => import("./../../../src/pages/summary.tsx" /* webpackChunkName: "component---src-pages-summary-tsx" */),
  "component---src-pages-tickets-tsx": () => import("./../../../src/pages/tickets.tsx" /* webpackChunkName: "component---src-pages-tickets-tsx" */),
  "component---src-pages-venue-tsx": () => import("./../../../src/pages/venue.tsx" /* webpackChunkName: "component---src-pages-venue-tsx" */)
}

